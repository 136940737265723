import { OrganisationalService } from "../HttpServices/GL/OrganisationalService";
import { Company } from "../Models/Company";
import { EntityState } from "../Models/EntityState";
import { WorkflowOperation } from "../Models/WorkflowOperation";
import { ErrorResponse } from "../Models/ErrorResponse";
import { IEntity } from "../Models/IEntity";
import Store, { AppKey } from "./Store";
import { OperationLink } from "../Models/OperationLink";
export class Util {
  setObjectState(
    objects: IEntity[],
    state: EntityState = EntityState.Unchanged
  ) {
    objects.forEach((obj) => {
      obj.state = state;
    });
  }
  static decimalPlaces ()
  {
    return 2
  }
  static treeSeparator():string{
    return "~";
  }
  static formatValue(value:any,decimalPlaces:number=2){
    if(!value){
      value= 0; 
    }
   const res= Number(value).toLocaleString(navigator.language,{ minimumFractionDigits: decimalPlaces});
   return res;
  }
  static dateFmt = "dd/mm/yy";
  static async getCompany() {
    var company = new Store().get(AppKey.COMPANY);
    if (!company || company instanceof ErrorResponse || company.message) {
      const service = new OrganisationalService();
      const result = await service.getFullCompany();
      if (result instanceof ErrorResponse || !result.message) {
        new Store().set(AppKey.COMPANY, result);
        return result as Company;
      }
      return null;
    } else {
      return company;
    }
  }
  static getBaseUrl(): string {
    let t = window.location.href.split("/")[2];
    t = t.includes("localhost") ? "pfs.applauseerp.com" : t;
    // t = t.includes("localhost") ? "japauloil.applauseerpdemo.com" : t;
    return t;
  }
  static getDateString(date: Date): string {
    // const offset = date.getTimezoneOffset();
    // date = new Date(date.getTime() - offset * 60 * 1000);
    return new Date(date).toISOString().split("T")[0];
  }

  static truncate(str: string = "", length: number = 10): string {
    if (str) {
      return str.length > length ? `${str.substring(0, length)}...` : str;
    } else return "";
  }

  static chunk<T>(arr: Array<T>, size: number): Array<[T]> {
    return arr.reduce(
      (acc, e, i) => (
        i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc
      ),
      [] as Array<[T]>
    );
  }

  static withinRange(value: number, start: number = 0, end: number = 999) {
    if (value < start) return start;
    else if (value > end) return end;
    else return value;
  }

  static log(...content: any) {
    let t = window.location.href.split("/")[2];
    if (t.includes("localhost")) {
      console.log(...content);
    }
  }

  static getWFOperations(key: string) {
    return this.wfOperationList().find((k) => k.name == key);
  }

  static wfOperationList() {
    return [
      {
        name: WorkflowOperation.JournalApproval.toString(),
        operations: ["Validate", "Post"],
      },
      {
        name: WorkflowOperation.FundTransfer.toString(),
        operations: ["Divide", "Post"],
      },
      {
        name: WorkflowOperation.VendorPaymentScheduleApproval.toString(),
        operations: ["Post", "Reverse"],
      },
      {
        name: WorkflowOperation.VendorInvoiceApproval.toString(),
        operations: ["Post", "Reverse"],
      },
      {
        name: WorkflowOperation.CustomerInvoiceApproval.toString(),
        operations: ["Post", "Reverse"],
      },
      {
        name: WorkflowOperation.StockTransferApproval.toString(),
        operations: ["Approve", "Release", "Receive", "Return", "Cancel"],
      }
    ];
  }

  static getOperationLink(operation: WorkflowOperation) {
    return {
      [WorkflowOperation.JournalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.PeriodClosing]: OperationLink.JournalApproval,
      [WorkflowOperation.Workflow]: OperationLink.JournalApproval,
      [WorkflowOperation.FundTransfer]: OperationLink.JournalApproval,
      [WorkflowOperation.DepreciationSchedule]: OperationLink.JournalApproval,
      [WorkflowOperation.RevaluationSchedule]: OperationLink.JournalApproval,
      [WorkflowOperation.SplittingSchedule]: OperationLink.JournalApproval,
      [WorkflowOperation.DisposalSchedule]: OperationLink.JournalApproval,
      [WorkflowOperation.SalarySchedule]: OperationLink.JournalApproval,
      [WorkflowOperation.StockTransferApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.StockTransferReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.CustomerInvoiceApproval]: OperationLink.CustomerInvoiceApproval,
      [WorkflowOperation.CustomerInvoiceReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.CustomerPaymentApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.CustomerPaymentReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.CustomerPaymentScheduleApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.CustomerPaymentScheduleReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.RecurringCustomerPaymentApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.VendorInvoiceApproval]: OperationLink.VendorInvoiceApproval,
      [WorkflowOperation.VendorInvoiceReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.VendorPaymentApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.VendorPaymentReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.VendorPaymentScheduleApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.VendorPaymentScheduleReversalApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.RecurringVendorPaymentApproval]: OperationLink.JournalApproval,
      [WorkflowOperation.AdjustmentJournal]: OperationLink.JournalApproval,
      [WorkflowOperation.None]: OperationLink.None
    }[operation]
  }

  private static getDateFormat(someDateTimeStamp: Date) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dt = new Date(someDateTimeStamp),
      date = dt.getDate(),
      month = months[dt.getMonth()],
      timeDiff = someDateTimeStamp.getTime() - Date.now(),
      diffDays = new Date().getDate() - date,
      diffMonths = new Date().getMonth() - dt.getMonth(),
      diffYears = new Date().getFullYear() - dt.getFullYear();

    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
      return "Today";
    } else if (diffYears === 0 && diffDays === 1) {
      return "Yesterday";
    } else if (diffYears === 0 && diffDays === -1) {
      return "Tomorrow";
    } else if (diffYears === 0 && diffDays < -1 && diffDays > -7) {
      return days[dt.getDay()];
    } else if (diffYears >= 1) {
      return (
        month + " " + date + ", " + new Date(someDateTimeStamp).getFullYear()
      );
    } else {
      return month + " " + date;
    }
  }

  static formatDate(input: Date): string {
    const hour = input.getHours(),
      minute = input.getMinutes();

    // return `${days[input.getDay()]}, ${input.getDate()} ${
    //   months[input.getMonth()]
    // } ${input.getFullYear()} ${input.getHours()}:${input.getMinutes()}`;

    return `${this.getDateFormat(input)}, ${(hour < 10 ? "0" : "") + hour}:${
      (minute < 10 ? "0" : "") + minute
    }`;
  }

  static formatUrlDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  // Fisher-Yates Shuffle Algorithm -
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle

static shuffle(array?: Array<String>): Array<String> {
  const newArray = array ? [...array] : [
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "teal",
    "blue",
    "violet",
    "purple",
    "pink",
    "brown",
    "grey",
  ];

  for (let i = newArray.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements array[i] and array[j]
    // we use "destructuring assignment" syntax to achieve that
    // you'll find more details about that syntax in later chapters
    // same can be written as:
    // let t = array[i]; array[i] = array[j]; array[j] = t
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}
}
